import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, of } from 'rxjs';
import { Credentials } from '../interfaces/credentials';
import { environment } from '../../environments/environment';
import { AgencyResponse } from '@interfaces/responses/agency-response';


@Injectable({
  providedIn: 'root'
})

export class UserService {
  resourceUrl: string = '';

  constructor(private http: HttpClient) { }

  setPasswordWithCode(email: string, currentPassword: string, newPassword: string): Observable<Credentials> {
    return this.http.post<Credentials>(
      environment.apiUrl + this.resourceUrl + '/api/forget/validateAndChange',
      {
        email,
        currentPassword,
        newPassword,
      },
      { headers: { showSpinner: 'true' } }
    );
  }

  changePassword(email: string, currentPassword: string, newPassword: string): Observable<Credentials> {
    return this.http.patch<Credentials>(
      environment.apiUrl + this.resourceUrl + '/api/user/password',
      {
        email,
        currentPassword,
        newPassword,
      },
      { headers: { showSpinner: 'true' } }
    );
  }

  sendCodeToEmail(email: string): Observable<string> {
    return this.http.post<string>(
      environment.apiUrl + this.resourceUrl + '/api/forget',
      email,
      { headers: { showSpinner: 'true' } }
    );
  }

  getAgency(): Observable<string> {
    const agencyId = localStorage.getItem('agencyId');
    if (agencyId) return of(agencyId);
    return this.http.get<AgencyResponse>(environment.apiUrl + '/api/gambler/me/operator').pipe(
      map((response) => {
        localStorage.setItem('agencyId', response.agency.id.toString());
        return response.agency.id.toString();
      })
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { FavoriteTicket } from '@models/ticket/favorite-ticket';
import { Ticket } from '@models/ticket/ticket';
import { FavoriteTicketResponse } from 'app/interfaces/responses/favorite-response';
import { TicketResponse } from 'app/interfaces/responses/ticket-response';
import { MakeATicket } from 'app/interfaces/ticket/make-a-ticket';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  resourceUrl: string = '/api/ticket';
  gamblerTicketUrl = environment.apiUrl + '/api/gambler';
  favoriteTickerUrl = environment.apiUrl + '/api/favoriteTicket';

  constructor(private http: HttpClient) {}

  makeATicket(ticket: MakeATicket): Observable<any>{
    return this.http.post<MakeATicket>(environment.apiUrl + this.resourceUrl, ticket);
   }

  loadTickets(pageNumber: number = 0): Observable<Ticket[]> {
    return this.http
      .get<TicketResponse>(
        `${this.gamblerTicketUrl}/tickets?page=${pageNumber}&size=10`
      )
      .pipe(
        map((response: TicketResponse) => {
          return response.content;
        })
      );
  }

  loadFavoriteTickets(pageNumber: number = 0): Observable<FavoriteTicket[]> {
    return this.http
      .get<FavoriteTicketResponse>(
        `${this.gamblerTicketUrl}/favoriteTickets?page=${pageNumber}&size=3`
      )
      .pipe(
        map((response: FavoriteTicketResponse) => {
          return response.content;
        })
      );
  }

  addFavoriteTicket(ticketId: number): Observable<FavoriteTicket> {
    return this.http.post<FavoriteTicket>(
      environment.apiUrl + this.resourceUrl + `/${ticketId}/favorite`,
      {}
    );
  }

  checkIsFavorite(ticketId: number): Observable<boolean> {
    return this.loadFavoriteTickets().pipe(
      map((favoriteTickets: FavoriteTicket[]) => {
        return favoriteTickets.some(
          (favorite) => favorite.originalId === ticketId
        );
      })
    );
  }

  deleteTicket(id: number): Observable<number> {
    return this.http
      .delete(`${environment.apiUrl + this.resourceUrl}/${id}`, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response.status;
        })
      );
  }

  deleteFavoriteTicket(id: number): Observable<number> {
    return this.http
      .delete(`${this.favoriteTickerUrl}/${id}`, { observe: 'response' })
      .pipe(
        map((response) => {
          return response.status;
        })
      );
  }

  acceptTicketAward(ticketId: number): Observable<any> {
    return this.http.patch(
      `${this.gamblerTicketUrl}/ticket/${ticketId}/accepted`,
      {}
    );
  }
}

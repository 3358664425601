import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Credentials } from '../interfaces/credentials';
import { UserData } from '../interfaces/credentials';
import { jwtDecode } from 'jwt-decode';
import { TokenService } from './token.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  resourceUrl: string = '/api';
  
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private router: Router) {}

  login(email: string, password: string): Observable<Credentials> {
    return this.http.post<Credentials>(
      environment.apiUrl + this.resourceUrl + '/login',
      {
        email,
        password,
      },
      { headers: { showSpinner: 'true' } }
    );
  }

  isLogged() {
    const token = localStorage.getItem('token') || null;
    const data = localStorage.getItem('userData') || null;

    if (token == null || data == null) {
      return false;
    }

    const decodedJwt = jwtDecode(token);
    const expirationDate = decodedJwt.exp || Number.MAX_VALUE;
    const now = Date.now();

    return expirationDate < now;
  }

  getUserData(): UserData {
    return JSON.parse(localStorage.getItem('userData') || '{}');
  }

  setUserData(userData: UserData) {
    localStorage.setItem('userData', JSON.stringify(userData));
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    this.tokenService.updateToken(false);
    this.router.navigate(['/']);
  }

  hasAcceptedTermsAndConditions(id: number): boolean {
    if (!localStorage.getItem('termsAndConditionsKey')) {
      return false;
    }
    const storedUserIds: number[] = JSON.parse(localStorage.getItem('termsAndConditionsKey') || '[]');
    return storedUserIds.includes(id);
  }



}


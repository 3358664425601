import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    localStorage.getItem('token') !== null
  );

  constructor() { }

  updateToken(status: boolean) {
    this.isAuthenticated.next(status);
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
    this.updateToken(true);
  }

  getToken() {
    return localStorage.getItem('token') || null;
  }
  
}

<div class="container">
  <div class="content">
    <img
      ngSrc="{{ data.iconPath }}"
      alt="image"
      class="image"
      width="80"
      height="80"
    />
    <div class="text">
      <h2>{{ data.title }}</h2>
      <p>{{ data.message }}</p>
    </div>
  </div>

  <div class="actions">
    @if(data.enableConfirm && data.onConfirm){
      <button class="btn-accept" (click)="data.onConfirm()">Aceptar</button>
    }
    @if(data.enableCancel && data.onCancel){
      <button class="btn-cancel" (click)="data.onCancel()">Cancelar</button>
    }
  </div>
</div>

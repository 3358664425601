import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { GamblerBalance } from '@interfaces/gambler/gambler-balance';
import { environment } from '@environments/environment';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root',
})
export class BalanceService {
    private balanceSubject: BehaviorSubject<GamblerBalance> = new BehaviorSubject<GamblerBalance>({ balance: -1, bonusBalance: -1 });
    public balance$: Observable<GamblerBalance> = this.balanceSubject.asObservable();
    public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private loginService: LoginService) {
        if (this.loginService.isLogged()) {
            this.loadBalance();
        }
    }

    private loadBalance(): void {
        this.isLoading.next(true);
        this.http.get<GamblerBalance>(environment.apiUrl + '/api/gambler/account/balance').subscribe({
            next: (newBalance) => {
                this.balanceSubject.next(newBalance);
            },
            error: (err) => {
                console.error('Error al obtener el saldo:', err);
            },
            complete: () => {
                this.isLoading.next(false);
            },
        });
    }

    watchBalanceUpdates(): Observable<GamblerBalance> {
        return this.balance$;
    }

    balanceUpdated(): void {
        this.loadBalance();
    }
}

import { NgOptimizedImage } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

export interface DialogData {
  iconPath?: string;
  title: string;
  message?: string;
  enableConfirm: boolean;
  enableCancel: boolean
  onConfirm?: () => void;
  onCancel?: () => void;
}

@Component({
  selector: 'app-custom-notification',
  standalone: true,
  imports: [MatDialogModule, NgOptimizedImage],
  templateUrl: './custom-notification.component.html',
  styleUrls: ['./custom-notification.component.css'],
})
export class CustomNotificationComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {}
}
